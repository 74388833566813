import gql from "graphql-tag";

export const GET_ALL_FORESTS = gql`
  query GET_ALL_FORESTS {
    allForests {
      nodes {
        forestCarbonoffset
        forestOxygen
        totalTrees
        totalSpeciesPlanted
      }
    }
  }
`;

export const GET_FOREST_BY_NODEID = gql`
  query GET_FOREST_BY_NODEID($nodeId: ID!) {
    forest(nodeId: $nodeId) {
      forestTreesByForestId {
        nodes {
          forestTreeName
          forestTreeHeight
          forestTreeGeoLong
          id
          nodeId
          forestTreeGeoLat
        }
      }
      forestSponsorsByForestId {
        nodes {
          sponsorBySponsorId {
            sponsorName
          }
        }
      }
    }
  }
`;

export const GET_TREES_BY_NODEID =(timelineOrder) => gql`
  query MyQuery($uniqId: String) {
  allForestTrees(condition: {treeUniqueId: $uniqId}) {
    nodes {
      forestTreeName
      treeUniqueId
      isActive
      forestTreeGeoLat
      forestTreeGeoLong
      forestTreeHeight
      forestTreeDia
      forestTreeAge
      forestTreeCarbonoffset
      forestTreeOxygen
      giftForestPlantsByGiftTreeId(condition: {isActive: true}) {
        nodes {
          orgLogoUrl
          organizationName
          name
          designation
          isActive
          userAssetUrl
          updatedAt
          allocatingOn
        }
      }
      forestPlantTimelinesByPlantId(orderBy: CREATED_AT_DESC) {
        nodes {
          id
          age
          height
          latitude
          longitude
          diameter
          masterPlantspecyBySpeciesId {
            commonName
            speciesName
          }
          treeStatusMasterByStatusId {
            status
          }
          forestPlantTimelineAssetsByTimelineId {
            nodes {
              id
              url
              isActive
              updatedAt
              createdAt
            }
          }
          createdAt
        }
      }
      forestByForestId {
        forestUniqueId
      }
    }
  }
}
`;

export const GET_TREES_BY_NODEID_1 = (data) => gql`
query MyQuery(
  $tree_unique_id: String
  ${data?.forest_unique_id ? `$forest_unique_id: String` : ``}
) {
  allForestTrees(
    filter: {
      treeUniqueId: { equalTo: $tree_unique_id }
      ${data?.forest_unique_id ? `forestByForestId: { forestUniqueId: { equalTo: $forest_unique_id } }` : ``}
    }
  ) {
    nodes {
      forestTreeName
      treeUniqueId
      tree_unique_id: treeUniqueId
      isActive
      forestTreeGeoLat
      forestTreeGeoLong
      forestTreeHeight
      forestTreeDia
      forestTreeAge
      forestTreeCarbonoffset
      forestTreeOxygen
      forestTreeSponsorsByForestTreeId {
        nodes {
          id
          sponsorBySponsorId {
            sponsorLogo
          }
        }
      }
      giftForestPlantsByGiftTreeId(condition: { isActive: true }) {
        nodes {
          orgLogoUrl
          organizationName
          name
          designation
          isActive
          userAssetUrl
          updatedAt
          allocatingOn
        }
      }
      forestPlantTimelinesByPlantId(orderBy: CREATED_AT_DESC) {
        nodes {
          id
          age
          height
          latitude
          longitude
          diameter
          masterPlantspecyBySpeciesId {
            commonName
            speciesName
          }
          treeStatusMasterByStatusId {
            status
          }
          forestPlantTimelineAssetsByTimelineId {
            nodes {
              id
              url
              isActive
              updatedAt
              createdAt
            }
          }
          createdAt
        }
      }
      forestByForestId {
        forestUniqueId
        forestSponsorsByForestId {
          nodes {
            id
            sponsorBySponsorId {
              sponsorLogo
            }
          }
        }
      }
    }
  }
}
`;

export const GET_SEARCH_LIST = gql`
  query GET_SEARCH_LIST($search: String) {
    forest: allForests(
      filter: { forestName: { includesInsensitive: $search } }
    ) {
      nodes {
        forestName
        nodeId
        forestGeoLat
        forestGeoLong
      }
    }
  }
`;

// trees: allForestTrees(filter: {forestTreeName: {includes: $search}}) {
//   nodes {
//     forestTreeName
//     nodeId
//     forestTreeGeoLong
//     forestTreeGeoLat
//     forestByForestId {
//       forestName
//       nodeId
//     }
//   }
// }

// export const GET_ALL_FORESTS_AND_TREES_PAGINATION = gql`
// query GET_ALL_FORESTS_AND_TREES($offset: Int, $limit: Int){
//   allForests(offset: $offset, first: $limit) {
//     totalCount
//     nodes {
//       forestName
//       id
//       nodeId
//       forestGeoLat
//       forestGeoLong
//       forestGeoRadius
//       forestTreesByForestId {
//         nodes {
//           id
//           nodeId
//           forestTreeName
//           forestTreeGeoLat
//           forestTreeGeoLong
//         }
//         totalCount
//       }
//     }
//   }
// }
// `;

export const GET_ALL_FORESTS_AND_TREES = gql`
  query GET_ALL_FORESTS_AND_TREES {
    allForests(orderBy: UPDATED_AT_ASC) {
      nodes {
        forestName
        nodeId
        id
        forestGeoLat
        forestGeoLong
        forestGeoRadius
        forestUniqueId
        forestBoundary
        forestSponsorsByForestId {
          nodes {
            sponsorBySponsorId {
              sponsorForestLogo
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_TREES = gql`
  query MyQuery($id: UUID) {
    allForestTrees(
      orderBy: FOREST_ID_DESC
      condition: { forestId: $id, isDisplay: true }
    ) {
      nodes {
        nodeId
        forestTreeName
        forestTreeGeoLat
        forestTreeGeoLong
        treeUniqueId
        tree_unique_id: treeUniqueId
        forestByForestId {
          forestUniqueId
        }
      }
    }
  }
`;

export const GET_FOREST_DETAILS_BY_ID = gql`
  query MyQuery($uniqId: String) {
    allForests(condition: { forestUniqueId: $uniqId }) {
      nodes {
        forestName
        forestDesc
        forestUrl
        forestCarbonoffset
        forestOxygen
        forestUniqueId
        totalSpeciesPlanted
        forestGeoLat
        forestGeoLong
        totalTrees
        forestAssertsByForestId {
          nodes {
            url
          }
        }
        forestSponsorsByForestId {
          nodes {
            sponsorBySponsorId {
              sponsorLogo
              sponsorName
            }
          }
        }
      }
    }
  }
`;

export const GET_FOREST_SEO_BY_UNIQUE_ID = gql`
  query ($unique_id: String!) {
    seoData: forestByForestUniqueId(forestUniqueId: $unique_id) {
      name: forestName
      total_trees: totalTrees
      total_species_planted: totalSpeciesPlanted
      oxygen_produced: forestOxygen
      carbon_offset: forestCarbonoffset
      sponsors: forestSponsorsByForestId {
        nodes {
          sponsorBySponsorId {
            sponsorName
            sponsorOgImageUrl
          }
        }
      }
    }
  }
`;

export const GET_TREE_SEO_BY_UNIQUE_ID = gql`
  query ($unique_id: String!) {
    seoData: forestTreeByTreeUniqueId(treeUniqueId: $unique_id) {
      name: forestTreeName
      forest: forestByForestId {
        name: forestName
      }
      species: masterPlantspecyByMasterPlantSpeciesId {
        name: speciesName
      }
      oxygen_produced: forestTreeOxygen
      carbon_offset: forestTreeCarbonoffset
      sponsors: forestTreeSponsorsByForestTreeId {
        nodes {
          sponsorBySponsorId {
            sponsorName
            sponsorOgImageUrl
          }
        }
      }
    }
  }
`;

export const GET_TREE_SEO_BY_FOREST_ID = gql`
query($unique_id: String!, $foerst_unique_id: String!) {
  seoData: allForestTrees(
    filter: {
      treeUniqueId: { equalTo: $unique_id }
      forestByForestId: { forestUniqueId: { equalTo: $foerst_unique_id } }
    }
  ) {
    nodes {
      name: forestTreeName
      forest: forestByForestId {
        name: forestName
      }
      species: masterPlantspecyByMasterPlantSpeciesId {
        name: speciesName
      }
      oxygen_produced: forestTreeOxygen
      carbon_offset: forestTreeCarbonoffset
      sponsors: forestTreeSponsorsByForestTreeId {
        nodes {
          sponsorBySponsorId {
            sponsorName
            sponsorOgImageUrl
          }
        }
      }
    }
  }
}
`;
