
import React from "react";
import { PrivacyPolicy } from "./privacypolicy";
import { withNavBars } from "./../../HOCs";

class HomeParent extends React.Component {
  render() {
    return <PrivacyPolicy {...this.props} />;
  }
}

export default withNavBars(HomeParent);
