import React, { useEffect, useState } from 'react';
import $ from "jquery";
import './plugin-4.0.0_bootstrap.min.css'
import './style.css';

export const PrivacyPolicy = () => {
    const [activeMenu, setActiveMenu] = useState(null);

  // Scroll behavior and update active menu on resize
  useEffect(() => {
    const scrollWin = (id) => {
      const elmnt = document.getElementById(id);
      $('html, body').animate(
        {
          scrollTop: $(elmnt).offset().top - 100,
        },
        600
      );
    };

    const handleResize = () => {
      const sections = [
        "id1",
        "id2",
        "id3",
        "id4",
        "id5",
        "id6",
        "id7",
        "id8",
        "id9",
        "id10",
      ];
      sections.forEach((section, index) => {
        if (document.getElementById(section)?.getBoundingClientRect().top <= window.innerHeight) {
          setActiveMenu(index);
        }
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = (index) => {
    setActiveMenu(index);
  };

    return(
        
   <div>
    <div class="whole_div">
        <div class="card1">
            <div class="row row_00">
                <div class=" row row_privacy col-sm-12 col-md-12  col-lg-12 col-xl-12">
                    Be The Tree Hugger Privacy Notice
                </div>
                <div class=" row  row_date col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    Effective Date: Jan 01st, 2024
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row head_row" id="headrow">
            <div class=" left col-sm-7 col-md-7  col-lg-7">
                <div id="id1" class="head_theme ">
                    <h4 class=" head_theme fontGraphikSemibold"> Introduction</h4>
                    <br/>
                    <p class="paragraph fontLatoRegular">
                        Be The Tree Hugger and its group companies (collectively &ldquo;Be The Tree Hugger&rdquo;, "we", "us", and "ours") is
                        committed to protecting your privacy. This Privacy Notice (&ldquo;Notice&rdquo;) describes how Be The Tree Hugger
                        processes Personal Data in its capacity as a controller (i.e. Be The Tree Hugger decides what Personal
                        Data is collected and what it is used for) or processor (i.e. Be The Tree Hugger only processes the
                        data as per the controller's instructions), as the case may be. It also describes your
                        choices regarding use, access, and correction of your Personal Data.
                    </p>

                </div>


                <div id="id2" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">What Personal Data Does Be The Tree Hugger Collect And Why?

                    </h4>
                    <br/>
                    <div class="article">
                        <h6 class="heads"> Personal Data we collect and process for
                            our own purposes: Collected Data
                        </h6>

                        <p class="p fontLatoRegular">
                            When you visit our Websites or participate in Be The Tree Hugger&rsquo;s events, Be The Tree Hugger may collect
                            information, which may include Personal Data, from Individuals as set forth below
                            (collectively referred to as &ldquo;Collected Data&rdquo;). For the purposes of General Data
                            Protection Regulation (GDPR), Be The Tree Hugger shall be the controller for the Collected Data &ndash;
                            this means that Be The Tree Hugger decides what Collected Data is processed and why.
                        </p>
                    </div>
                </div>

                <div id="id3" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">When does Be The Tree Hugger process Collected Data?


                    </h4>
                    <br/>
                    <div class="article">
                        <h6 class="heads">Sign-up, billing, and Account
                            information.

                        </h6>

                        <p class="p fontLatoRegular">
                        </p><p class="sub-paragraph"> (i)
                            contact
                            information such as name, e-mail address, mailing address, IP address, geographic
                            location, or phone number of the Account admin </p>
                        <p class="sub-paragraph"> When you subscribe and sign-up to any of our Service(s), we may
                            collect your </p>
                        <p class="sub-head">(ii) name and e-mail address when
                            Account admin/Agent(s) provide feedback from within the Service(s) </p>
                        <p class="sub-head"> (iii) unique
                            identifiers, such as username, account number or password.</p>


                        <p class="sub-head">
                            Subject to this Notice and the Terms, we will use such data, including without
                            limitation, to
                        </p>
                        <p class="sub-head">(i) Provide you the Service(s) </p>
                        <p class="sub-head">(ii) Send you communication from the Service(s)</p>

                        <p class="sub-head">(iii) Assess needs of your business to determine or suggest suitable
                            Service(s)</p>

                        <p class="sub-head">(iv) Send you requested information about the Service(s)</p>

                        <p class="sub-head"> (v) Respond to
                            customer service requests, questions and concerns </p>

                        <p class="sub-head"> (vi) Administer your Account</p>

                        <p class="sub-head">(vii)
                            Send you promotional and marketing communications</p>

                        <p class="sub-head">(viii) facilitate your transactions with other users when you use our
                            Service(s).</p>


                        
                    </div>

                    <div class="article">
                        <h6 class="heads"> Career at Be The Tree Hugger
                        </h6>
                        <p class="p fontLatoRegular">
                            When you apply for an open position by populating the application form, we may collect
                            &nbsp;&nbsp;
                        </p><p class="sub-head">
                            (i) contact information such as name, email address, mailing
                            address, phone
                            number, links to your social networking profiles.
                        </p>
                        <p class="paragraph">

                            (ii) any other information
                            contained in the resume that you submit to us.

                            Subject to this Notice, we will use such data to evaluate you for the open position that
                            you have applied for or any position that we consider you suitable for at the time you
                            submit your resume or at any later date. Unless you notify us otherwise by an email to
                            bethetreehugger.co, we will retain such data for a period of 1 year for archival purposes.
                            If you wish to update the data you provided to us, you may do so by contacting us at
                            bethetreehugger.co.</p>

                        <p class="paragraph">
                            For the purposes of evaluating you for an open position, you understand that we may
                            internally rate you based on parsing of your resume and your information. If you do not
                            wish to be rated by us, please do not provide us your information.
                        </p>
                        
                    </div>
                    <div class="article">
                        <h6 class="heads"> Events

                        </h6>

                        <p class="p fontLatoRegular">
                        </p><p class="sub-head">
                            When you attend an event conducted by Be The Tree Hugger, including webinars or seminars, we may
                            collect your contact information such as name, e-mail address, designation and company
                            name.</p>
                        <p class="paragraph">

                            Subject to this Notice, we will use such data, including without limitation, to
                        </p>
                        <p class="sub-head">
                            (i) Assess needs of your business to determine or suggest suitable Service(s)</p>
                        <p class="sub-head">
                            (ii) send you requested information about the Service(s)</p>
                        <p class="sub-head">
                            (iii) send you promotional and marketing communications</p>
                        <p class="sub-head">
                            (iv) respond to your
                            questions and concerns.
                        </p>

                        <br/>
                    </div>

                    <div class="article">
                        <h6 class="heads"> Public forums, Forms and Newsletters.

                        </h6>


                        <p class="paragraph">
                            When you visit our publicly accessible community forums and blogs or submit any forms on
                            our Website, you should be aware that any information you provide in these areas may be
                            read, collected, and used by others who access them. Further, we may collect your
                        </p>
                        <p class="sub-head">
                            (i)
                            Contact information such as name, e-mail address, mailing address, or phone number</p>
                        <p class="sub-head">
                            (ii)
                            Information about your business, such as company name, company size, business type</p>
                        <p class="sub-head">
                            (iii) A short bio about you to identify you as the author of the post. When you
                            actively
                            subscribe to our newsletters, we collect your e-mail address to share our newsletters
                            with you.
                        </p>

                        <p class="paragraph">Subject to this Notice, we will use such data, including without
                            limitation, to</p>
                        <p class="sub-head">
                            (i)
                            Assess needs of your business to determine or suggest suitable Service(s)</p>
                        <p class="sub-head">
                            (ii) Send you
                            requested information about the Service(s)
                        </p>
                        <p class="sub-head">
                            (iii) Send you promotional and marketing
                            communications
                        </p>
                        <p class="sub-head">
                            (iv) Respond to your
                            questions and concerns.
                        </p>
                    </div>
                    <div class="article">
                        <h6 class="heads"> Cookies and Similar Technologies.
                        </h6>
                        <p class="paragraph"> We and our third party advertising partners use cookies and similar
                            technologies in
                            analyzing trends, administering the website, tracking users&rsquo; movements around the site,
                            and gathering demographic information about our user base as a whole. We may receive
                            reports based on the use of these technologies by these companies on an individual and
                            aggregated basis. Most web browsers support cookies and users can control the use of
                            cookies at the individual browser level. Please note that if you choose to disable
                            cookies, it may limit your use of certain features or functions on our Websites and
                            services.</p>


                        <p class="paragraph">
                            As is true of most websites, we gather certain information automatically and store it in
                            log files. This information may include internet protocol (IP) addresses, browser type,
                            internet service provider (ISP), referring/exit pages, the files viewed on our Website
                            (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or
                            clickstream data. We link this automatically collected data to other data we collect
                            about you. We do this mainly to improve services We offer you, to improve marketing,
                            analytics, and/or Website performance and functionality.</p>


                    </div>

                    <div class="article">
                        <h6 class="heads">Analytics.


                        </h6>

                        <p class="paragraph">
                            Apart from the aforementioned information collected by us, we automatically receive and
                            record certain Personal Data of yours when You visit our Websites. This includes device
                            model, IP address, the type of browser being used, usage pattern through cookies and
                            browser settings, query logs and product usage logs. We also collect clicks, scrolls,
                            conversion and drop-off on our Websites and Service(s) to render user journeys in
                            real-time. Subject to this Notice, we will use such data, including without
                            limitation</p>
                        <p class="sub-head">(i) Assess needs of your business to determine or suggest suitable
                            Service(s)</p>
                        <p class="sub-head">(ii)
                            Send you requested information about the Service(s)</p>
                        <p class="sub-head">(iii) Respond to customer service
                            requests, questions and concerns
                        </p>
                        <p class="sub-head">(iv) For analytical purposes.</p>

                        <p class="paragraph"> You authorize Be The Tree Hugger and its service providers to perform analytics on
                            such
                            Collected</p>
                        <p class="sub-head">
                            (i) Improve, enhance, support and operate the Websites<br/> <br/>(ii) Compile
                            statistical reports and record insights into usage patterns. You acknowledge that
                            Be The Tree Hugger uses Collected Data, as the case may be, for the aforementioned purposes.</p>
                         <p class="paragraph">
                            
                            When you visit or log in to our website, cookies  and similar technologies may be used by our online data partners or vendors to associate these activities with other personal information they or others have about you, including by association with your email. We (or service providers on our behalf) may then send communications and marketing to these email. You may opt out of receiving this advertising by visiting https://app.retention.com/optout
                        </p>

                    </div>

                    <div class="article">
                        <h6 class="heads"> Testimonials.
                        </h6>
                        <p class="paragraph">
                            We may post your testimonials/comments/reviews on our Websites which may contain your
                            Personal Data. Prior to posting the testimonial, we will obtain your consent to post
                            your name along with the testimonial. If you want your testimonial removed, please
                            contact us at bethetreehugger.co .</p>

                    </div>
                    <div class="article">
                        <h6 class="heads"> Marketing communications.
                        </h6>

                        <p class="paragraph">
                            When you have agreed to, we may use your e-mail address, collected as part of Collected
                            Data, to send our newsletters and/or marketing communications about our products and
                            services. Where you have so requested, we will also send you marketing communications
                            about our third party partners. If you no longer wish to receive these communications,
                            you can opt out by following the instructions contained in the e-mails you receive or by
                            contacting us at bethetreehugger.co.
                        </p>

                    </div>
                </div>
                <div id="id4" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        Third-party disclosure
                    </h4>

                    <div class="article">
                        <p class="paragraph">
                            We do not sell, trade, or otherwise transfer to outside parties your Personally
                            Identifiable Information.
                        </p>
                    </div>
                </div>

                <div id="id5" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        Third-party links
                    </h4>

                    <div class="article">
                        <p class="paragraph">
                            We do not include or offer third-party products or services on our website.

                        </p>
                    </div>
                </div>

                <div id="id6" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        CAN SPAM Act
                    </h4>

                    <div class="article">
                        <p class="paragraph">
                            The CAN-SPAM Act is a law that sets the rules for commercial email, establishes
                            requirements
                            for commercial messages, gives recipients the right to have emails stopped from being
                            sent
                            to them, and spells out tough penalties for violations.
                        </p>

                        <h6 class="heads">
                            We collect your email address in order to:

                        </h6>

                        <p class="paragraph">
                            Send information, respond to inquiries, and/or other requests or questions

                        </p>

                        <h6 class="heads">
                            To be in accordance with CANSPAM, we agree to the following:

                        </h6>



                        <p class="sub-head fontLatoRegular">
                            Not use false or misleading subjects or email addresses.</p>
                        <p class="sub-head fontLatoRegular">
                            Identify the message as an advertisement in some reasonable way.</p>
                        <p class="sub-head fontLatoRegular">
                            Include the physical address of our business or site headquarters.</p>
                        <p class="sub-head fontLatoRegular">
                            Monitor third-party email marketing services for compliance, if one is used.</p>
                        <p class="sub-head fontLatoRegular">
                            Honor opt-out/unsubscribe requests quickly.</p>
                        <p class="sub-head fontLatoRegular">
                            Allow users to unsubscribe by using the link at the bottom of each email.</p>

                        <h6 class="heads">
                            If at any time you would like to unsubscribe from future emails, you can email us at :


                        </h6>

                        <p class="paragraph fontLatoRegular">
                            bethetreehugger.co and we will promptly remove you from ALL correspondence.
                        </p>

                    </div>
                </div>

                <div id="id7" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        COPPA (Children Online Privacy Protection Act)

                    </h4>

                    <div class="article">
                        <p class="paragraph fontLatoRegular">
                            When it comes to the collection of personal information from children under the age of
                            13 years old, the Children&rsquo;s Online Privacy Protection Act (COPPA) puts parents in
                            control. The Federal Trade Commission, United States&rsquo; consumer protection agency,
                            enforces the COPPA Rule, which spells out what operators of websites and online services
                            must do to protect children&rsquo;s privacy and safety online.
                            We do not specifically market to children under the age of 13 years old.


                        </p>
                    </div>
                </div>

                <div id="id8" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        Privacy Notice for California Residents

                    </h4>

                    <div class="article">

                        <p class="paragraph fontLatoRegular">

                            Collection of Personal Information. The categories of personal information we have
                            collected in the twelve (12) months prior to the Effective Date and that we may collect
                            include:
                        </p>


                        <p class="paragraph fontLatoRegular">
                            A) Identifiers such as a real name, alias, postal address, unique personal identifier,
                            online identifier, Internet Protocol address, email address, account name, a device
                            identifier; an Internet Protocol address; cookies, beacons, pixel tags, mobile ad
                            identifiers or similar technology; customer number, unique pseudonym or user alias;
                            telephone numbers, or other forms of persistent or probabilistic identifiers that can be
                            used to identify a particular consumer or device;
                        </p>
                        <p class="paragraph fontLatoRegular">
                            B) Signature, physical characteristics or description, telephone number, state
                            identification card number, insurance policy number, employment, employment history,
                            bank account number, credit card number, debit card number or any other financial
                            information, medical information or health insurance information;
                        </p>
                        <p class="paragraph fontLatoRegular">
                            C) Internet or other electronic network activity information, including, but not limited
                            to, browsing history, search history and information regarding a consumer&rsquo;s interaction
                            with an internet website, app or advertisement.
                        </p>
                        <p class="paragraph fontLatoRegular">
                            D) Geolocation data
                        </p>
                        <p class="paragraph fontLatoRegular">
                            E) Audio, electronic, visual, thermal, olfactory or similar information.
                            We collect this personal information for business purposes as described in the above
                            section of our general privacy policy above.
                        </p>


                        <p class="paragraph fontLatoRegular">
                            2. Disclosure of Personal Information. We have disclosed the categories of personal
                            information listed in clause 1 above for a business purpose in the twelve (12)
                            months prior to the Effective Date and may disclose such personal information to
                            service providers who support our business provided they do not sell such personal
                            information or retain, use or disclose such information for any purpose other than
                            for the specific purpose of performing the services specified in our contract with
                            them. We do not sell (as the term is defined in the CCPA) the personal information.
                            We collect, including personal information of minors under sixteen (16) years of
                            age, and will not sell it without providing a right to opt out. We have not sold (as
                            the term is defined in the CCPA) any personal information in the twelve (12) months
                            prior to the Effective Date.

                        </p>

                        <p class="paragraph fontLatoRegular">
                            3. Your Rights. You have certain rights, subject to certain limitations under applicable
                            law, with respect to your personal information, including:</p>
                        <p class="sub-head">
                            a) The right to request disclosure of details of personal information collected.</p>
                        <p class="sub-head">
                            b) The right to request disclosure of information sold (as defined in the CCPA).</p>
                        <p class="sub-head">
                            c) The right to deletion of your personal information.</p>
                        <p class="sub-head">
                            d) The right to &ldquo;opt out&rdquo; of any sale (as defined in the CCPA), if such sale occurs.</p>
                        <p class="paragraph">
                            e) The right not to be discriminated against for exercising your rights under CCPA.
                            California consumers may make a request pursuant to their rights under the CCPA by
                            contacting us at bethetreehugger.co or via mail to our postal address set forth above. We
                            will verify your request using the information associated with your account, including
                            e-mail address and/or Government identification. We will let you know if we need
                            additional information to verify your identity. Consumers may also designate an
                            authorised agent to exercise these rights on their behalf.</p>
                        <p class="paragraph">
                            4. Where we are a service provider. We may also act as a service provider (as the term
                            is defined in the CCPA). When we act as a service provider, including by providing our
                            services to another company that you interact with, we follow the instructions of the
                            company that engaged us as a service provider and you should directly contact that
                            company if you have any questions or would like more details on how your personal
                            information is processed by that company.</p>

                    </div>
                </div>

                <div id="id9" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        Your Consent
                    </h4>

                    <div class="article">
                        <p class="paragraph fontLatoRegular">
                            By using our site, you consent to our online privacy policy.
                        </p>
                    </div>
                </div>

                <div id="id10" class="head_theme">
                    <h4 class="head_theme fontGraphikSemibold">
                        Contacting Us
                    </h4>

                    <p class="paragraph fontLatoRegular">
                        If there are any questions regarding this privacy policy, the practices of this website or
                        your dealings with this website, you may contact us using the information below:
                    </p>
                    <p class="paragraph fontLatoRegular">
                        Makesh Gopalakrishnan<br/>
                        2/498, Ayshika, Sunrise Avenue 2nd Cross Street,
                        Neelankarai, Chennai, India
                        <br/>
                        Phone: +(91) 74483 90100
                        <br/>
                        Email:makesh@crayond.com 
                    </p>
                    <br/>
                </div>
            </div>
            <section id="right_side_content" className="right_side col-sm-5 col-md-5 col-lg-5 hidden-xs">
      <div className="container">
        <div className="head_right fontGraphikSemibold">PRIVACY POLICY</div>

        <section
          className={`row linksz menu1 fontLatoRegular ${activeMenu === 1 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id1")}
        >
          <span className="cb-factor__item_1" id="item1"></span> Introduction
        </section>
        <section
          className={`row linksz menu2 fontLatoRegular ${activeMenu === 2 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id2")}
        >
          <span className="cb-factor__item_1" id="item1"></span> What Personal Data Be The Tree Hugger Collect?
        </section>
        <section
          className={`row linksz menu3 fontLatoRegular ${activeMenu === 3 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id3")}
        >
          <span className="cb-factor__item_1" id="item1"></span> When Be The Tree Hugger process Data?
        </section>
        <section
          className={`row linksz menu4 fontLatoRegular ${activeMenu === 4 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id4")}
        >
          <span className="cb-factor__item_1" id="item1"></span> Third-party disclosure
        </section>
        <section
          className={`row linksz menu5 fontLatoRegular ${activeMenu === 5 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id5")}
        >
          <span className="cb-factor__item_1" id="item1"></span> Third-party links
        </section>
        <section
          className={`row linksz menu6 fontLatoRegular ${activeMenu === 6 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id6")}
        >
          <span className="cb-factor__item_1" id="item1"></span> CAN SPAM Act
        </section>
        <section
          className={`row linksz menu7 fontLatoRegular ${activeMenu === 7 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id7")}
        >
          <span className="cb-factor__item_1" id="item1"></span> COPPA
        </section>
        <section
          className={`row linksz menu8 fontLatoRegular ${activeMenu === 8 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id8")}
        >
          <span className="cb-factor__item_1" id="item1"></span> Privacy Notice for California
        </section>
        <section
          className={`row linksz menu9 fontLatoRegular ${activeMenu === 9 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id9")}
        >
          <span className="cb-factor__item_1" id="item1"></span> Your Consent
        </section>
        <section
          className={`row linksz menu10 fontLatoRegular ${activeMenu === 10 ? "sidePadding" : ""}`}
          onClick={() => scrollWin("id10")}
        >
          <span className="cb-factor__item_1" id="item1"></span> Contacting Us
        </section>
      </div>

      {/* Footer */}
      <div id="footer"></div>
    </section>
        </div>
    </div>
    

    <div id="footer"></div>
    </div>
    );
function scrollWin(x) {
    const elmnt = document.getElementById(x);
    if (elmnt) {
      window.scrollTo({
        top: elmnt.offsetTop - 150,
        behavior: "smooth",
      });
    }
  }
};


