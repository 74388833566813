import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
// import { LocalStorageKeys } from '../../../utils';
import Logo from '../../../assets/logo.png';
import { AsyncPaginateComponenet } from '../../asyncPaginate';
import { useApolloClient } from "@apollo/client";
import { GET_SEARCH_LIST } from '../../../graphql/queries';
import { SearchIcon } from '../../svg';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 0.41,
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down(600)]: {
            flexGrow: 1
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
        '& .MuiToolbar-root': {
            flexWrap: "wrap",
            gap: "10px",
            padding: "10px"
        }
    },
    title: {
        display: 'block',
        color: "#000"
    },
    titleContainer: {
        // marginLeft: theme.spacing(2)
    },
    menuIcon: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    }
}));

export const TopNavBar = (props) => {

    const {
        searchValue,
        onChangeSearch,
        // options,
    } = props;

    const location = useLocation();

    const classes = useStyles();

    const client = useApolloClient();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [viewSearch, setViewSearchSearch] = useState(false);
    const [isContributeRoute, setIsContributeRoute] = useState(false);

    const constructTreeName = (name1, name2) => `${name1} - (${name2})`

    const loadOptions = async (search = "", array) => {
        setLoading(true);

        let result, offset = 0;

        if (search && !Boolean(array?.length)) {
            offset = 0;
        }
        else {
            offset = array?.length;
        }

        result = await client.query({
            query: GET_SEARCH_LIST,
            variables: {
                search
            }
        }).then((res) => {
            let forestList = res.data.forest.nodes.map(_ => ({
                label: _.forestName,
                value: _.nodeId,
                findByNodeId: _.nodeId,
                lat: _?.forestGeoLat,
                lng: _?.forestGeoLong,
                ..._
            }));
            // let treeList = res.data.trees.nodes.map(_ => ({
            //     label: constructTreeName(_.forestTreeName, _?.forestByForestId?.forestName),
            //     value: _?.nodeId,
            //     findByNodeId: _?.forestByForestId?.nodeId,
            //     lat: _?.forestTreeGeoLat,
            //     lng: _?.forestTreeGeoLong,
            //     ..._
            // }));
            return [...forestList]
            // return [...forestList, ...treeList]
        }).catch((err) => {
            console.log(err)
        })

        setLoading(false);
        return {
            options: result,
            // hasMore: (array?.length + result?.contact?.length) < result?.count[0]?.count
            hasMore: false
        }

    }

    const clickIcon = () => {
        // if(viewSearch){
        setViewSearchSearch(!viewSearch)
        // }
    }

    useEffect(() => {

        const isInContributionRoute = location.pathname.includes('/contribution');
        const isPrivacyPolicyRoute = location.pathname.includes('/privacypolicy');


        if ( isInContributionRoute || isPrivacyPolicyRoute ) {
            setIsContributeRoute(true);
        } else {
            setIsContributeRoute(false);
        }
    }, [location?.pathname])

    return (
        <div className={classes.grow}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar {...(isContributeRoute === true ? { sx: { display: 'flex', justifyContent: 'start' } } : {})}>
                    {(isContributeRoute === false) && <>
                        {/* <IconButton className={classes.menuIcon} onClick={toogleSideNavBar} size="large">
                        <MenuIcon htmlColor="white" />
                    </IconButton> */}

                        {
                            (!viewSearch) &&
                            <>
                                {/* <Stack direction="row" alignItems="center" gap={"10px"}> */}
                                <img src={Logo} alt="logo" style={{ height: 44 }} />
                                {/* </Stack> */}
                                <Box sx={[
                                    {
                                        "@media (max-width:327px)": {
                                            display: "none"
                                        }
                                    }
                                ]}
                                    className={classes.grow} />
                            </>
                        }
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"10px"}
                            sx={[{
                                display: "none",
                                "@media (max-width:600px)": {
                                    flex: viewSearch ? 1 : 0,
                                    display: "flex"
                                },
                            }]}
                        >
                            {
                                viewSearch &&
                                <AsyncPaginateComponenet
                                    isPaginate
                                    loadOptions={(search, array) => loadOptions(search, array)}
                                    loading={loading}
                                    debounceTimeout={800}
                                    value={searchValue}
                                    onChange={(value) => {
                                        onChangeSearch(value);
                                    }}
                                    placeholder="Search"
                                />}
                            <IconButton
                                sx={{
                                    backgroundColor: "#1100002b",
                                }}
                                onClick={() => clickIcon()}
                            >
                                {
                                    viewSearch ? (<CloseIcon />) : (<SearchIcon />)
                                }
                            </IconButton>
                        </Stack>
                        <Box
                            sx={[{
                                minWidth: "300px",
                                "@media (max-width:600px)": {
                                    display: "none"
                                },
                            }]}
                        >
                            {
                                <AsyncPaginateComponenet
                                    isPaginate
                                    loadOptions={(search, array) => loadOptions(search, array)}
                                    loading={loading}
                                    debounceTimeout={800}
                                    value={searchValue}
                                    onChange={(value) => {
                                        onChangeSearch(value);
                                    }}
                                    placeholder="Search"
                                />}
                        </Box>
                    </>}
                    {isContributeRoute === true && <>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={"10px"}>
                            <img src={Logo} alt="logo" style={{ height: 44 }} />
                        </Stack>
                    </>}
                </Toolbar>
            </AppBar>
        </div>
    );
}
