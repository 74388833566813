import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
// import PrivateRouter from "./privateRouter";

import {
  NotFound,
  Home,
  AddTree,
  PrivacyPolicy
  // Login
} from './../screens';
import Privacypolicy from "../screens/privacypolicy";

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>

        {/* Login Route */}
        {/* <Route exact path={Routes.login}>
          <Login />
        </Route> */}

        {/* Home Route */}
        <Route exact path={Routes.home}>
          <Home />
        </Route>

        <Route exact path={Routes.contribution}>
          <Home />
        </Route>

        <Route exact path={Routes.forest}>
          <Home />
        </Route>

        <Route exact path={Routes.tree}>
          <Home />
        </Route>
        <Route exact path={Routes.privacypolicy}>
          <Privacypolicy />
        </Route>
        <Route>
          <AddTree exact path={Routes.addTree}/>
        </Route>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
